import React, { useState } from "react";
import "./App.css";

function getImgUrl() {
  const randomIndex = Math.floor(Math.random() * 500);
  return `https://d6hj3hjyzjujn.cloudfront.net/${randomIndex}.png`;
}

function App() {
  const [imgUrl, setImgUrl] = useState(getImgUrl());
  return (
    <div className="App mt-[8%] mb-8 md:mb-auto">
      <div className="overall-border m-auto w-[80vw] xl:w-[70vw] 2xl:w-[62vw] flex flex-col lg:flex-row p-8 border-2 border-amber-500 rounded-xl">
        <div className="w-[100%] aspect-square">
          <img
            src={imgUrl}
            className="w-[100%]"
            alt="degod-pic"
            onError={() => setImgUrl(getImgUrl())}
          />
        </div>
        <div className="text-white mx-4 text-xl flex flex-col my-auto">
          <span className="font-bold text-4xl mt-4">
            This DeGod does not exist.
          </span>
          <span className="mt-8">
            It was generated by an AI trained on 10,000{" "}
            <a
              href="https://degods.com/"
              target="_blank"
              rel="noreferrer"
              className="text-orange-300"
            >
              DeGods
            </a>
            . Created by{" "}
            <a
              href="https://twitter.com/yesthisishenry"
              target="_blank"
              rel="noreferrer"
              className="text-blue-400"
            >
              @yesthisishenry
            </a>{" "}
            for fun and entertainment.
          </span>
          <span className="italic mt-12">
            This site is not affiliated with DeGods.
          </span>
        </div>
      </div>
    </div>
  );
}

export default App;
